<template>
  <div>
    <!-- Header -->
    <div class="d-md-flex align-items-center">
      <div>
        <h3 class="card-title">
          FanBox
        </h3>

        <h5 class="card-subtitle text-muted font-weight-normal">
          Overview
        </h5>
      </div>

      <div class="ml-auto d-flex no-block align-items-center">
        <h3 class="mr-3">
          Crescimento da base
        </h3>

        <vs-select
          v-model="selectedSystem"
          class="w-250px mr-2"
          :disabled="systemOptions.length < 2"
        >
          <vs-select-item
            :value="0"
            text="Todas as origens"
          />

          <vs-select-item
            v-for="item in systemOptions"
            :key="item.value"
            :value="item.value"
            :text="item.text"
          />
        </vs-select>

        <vs-select
          v-model="overviewZoom"
          class="w-250px mr-2"
        >
          <vs-select-item
            v-for="item in zoomOptions"
            :key="item.value"
            :value="item.value"
            :text="item.text"
          />
        </vs-select>

        <vs-button
          class="btn btn-secondary text-light"
          icon="sync"
          @click="getChart(); getOverview();"
        >
          Atualizar dados
        </vs-button>
      </div>
    </div>

    <!-- Chart and Data -->
    <vs-row vs-justify="center">
      <vs-col
        vs-lg="4"
        vs-xs="12"
        vs-sm="4"
      >
        <!-- Fan Ids -->
        <h1
          v-if="overview.totalFanIds"
          class="mt-4 mb-0"
        >
          {{ overview.totalFanIds | formatLocale }}
        </h1>
        <div
          v-else
          class="skeleton skeleton-heading skeleton-footer mb-2 mt-4"
        />

        <h6 class="font-weight-light text-muted">
          {{ overview.descFanId || 'Fan IDs' }}
        </h6>

        <!-- Ids -->
        <h1
          v-if="overview.totalIds"
          class="mb-0 mt-4"
        >
          {{ overview.totalIds | formatLocale }}
        </h1>
        <div
          v-else
          class="skeleton skeleton-heading skeleton-footer mb-2 mt-4"
        />

        <h6 class="font-weight-light text-muted">
          IDs Únicos
        </h6>
      </vs-col>

      <vs-col
        vs-lg="8"
        vs-xs="12"
        vs-sm="8"
      >
        <!-- Key attribute is responsible for updating/re-rendering the chart -->
        <!-- Please do not remove -->
        <vue-apex-charts
          :key="series[0].data"
          height="250"
          type="area"
          :options="chartOptions"
          :series="series"
        />
      </vs-col>
    </vs-row>

    <!-- Footer -->
    <hr class="custom-hr">

    <MonthlyProfitList :totalincomes="totalIncomes" />
  </div>
</template>

<script>
/* Components */
import VueApexCharts from 'vue-apexcharts';
import MonthlyProfitList from '../box-fanbase/IDProfilesList';

export default {
  name: 'FanProfiles',
  components: {
    VueApexCharts,
    MonthlyProfitList,
  },
  props: {
    chart: {
      type: Array,
      required: true,
    },
    overview: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    /* Chart */
    series: [
      {
        name: 'Acumulado',
        data: [],
      },
      {
        name: 'Registros',
        data: [],
      },
    ],
    chartOptions: {
      dataLabels: {
        enabled: false,
      },
      chart: {
        toolbar: {
          show: true,
        },
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      colors: [],
      xaxis: {
        categories: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
        labels: {
          style: {
            cssClass: 'text-muted fill-color',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            cssClass: 'text-muted fill-color',
          },
        },
      },
      markers: {
        size: 3,
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
        theme: 'dark',
      },
      legend: {
        show: false,
      },
      grid: {
        show: true,
        borderColor: 'rgba(0,0,0,0.1)',
      },
    },
    /* Overview */
    totalIncomes: [
      {
        gridlg: 'vs-lg-2',
        gridsm: 'vs-sm-2',
        iconcolor: 'primary',
        icon: 'fa fa-user',
        textcolor: 'text-dark',
        text: 'IDs Únicos',
        money: '--',
      },
      {
        gridlg: 'vs-lg-2',
        gridsm: 'vs-sm-6',
        iconcolor: 'primary',
        icon: 'fa fa-address-book',
        textcolor: 'text-dark',
        text: 'CPFs',
        money: '--',
      },
      {
        gridlg: 'vs-lg-2',
        gridsm: 'vs-sm-6',
        iconcolor: 'primary',
        icon: 'mdi mdi-email',
        textcolor: 'text-dark',
        text: 'IDs com email',
        money: '--',
      },
      {
        gridlg: 'vs-lg-2',
        gridsm: 'vs-sm-6',
        iconcolor: 'primary',
        icon: 'mdi mdi-cellphone',
        textcolor: 'text-dark',
        text: 'IDs com telefone',
        money: '--',
      },
      {
        gridlg: 'vs-lg-2',
        gridsm: 'vs-sm-6',
        iconcolor: 'primary',
        icon: 'mdi mdi-home',
        textcolor: 'text-dark',
        text: 'IDs com endereço',
        money: '--',
      }
    ],
    totalVerified: 0,
    /* System */
    systemOptions: [],
    selectedSystem: 0,
    /* Zooms */
    overviewZoom: 'y',
    zoomOptions: [
      { text: 'Últimos 24 meses', value: 'm_24' },
      { text: 'Últimos 12 meses', value: 'y' },
      { text: 'Últimos 6 meses', value: 'm_6' },
      { text: 'Últimos 3 meses', value: 'm_3' },
      { text: 'Últimos 2 meses', value: 'm_2' },
      { text: 'Último mês', value: 'm' },
      { text: 'Últimas 3 semanas', value: 'w_3' },
      { text: 'Últimas 2 semanas', value: 'w_2' },
      { text: 'Última semana', value: 'w' },
    ],
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    primaryColor() {
      return this.$store.state.configBox.cor1;
    },
    secondaryColor() {
      return this.$store.state.configBox.cor2;
    },
  },
  watch: {
    chart() {
      this.setChart();
    },
    overview() {
      this.setOverview();
      this.setSystemOptions();
    },
    overviewZoom() {
      this.getChart();
    },
    selectedSystem() {
      this.setChart();
    },
  },
  created() {
    this.setChartColors();
  },
  mounted() {
    this.setChart();
    this.setOverview();
    this.setSystemOptions();
  },
  methods: {
    /* API */
    getChart() {
      const payload = {
        ownerId: this.ownerId,
        options: { zoom: this.overviewZoom },
      };

      this.$emit('get-chart', payload);
    },
    getOverview() {
      this.$emit('get-overview');
    },
    /* Helpers */
    getMonth(m) {
      const monthNames = {
        1: 'Jan', 2: 'Fev', 3: 'Mar', 4: 'Abr',
        5: 'Mai', 6: 'Jun', 7: 'Jul', 8: 'Ago',
        9: 'Set', 10: 'Out', 11: 'Nov', 12: 'Dez',
      };

      return monthNames[m] || '';
    },
    setChart() {
      let valAnt = 0;

      // Reset chart categories and series
      this.chartOptions.xaxis.categories = [];
      this.series[0].data = [];
      this.series[1].data = [];

      // Get chart data by selected system
      let data = this.chart.find((item) => item.systemID === this.selectedSystem).idsByMonth;

      // Set chart categories and series
      const categories = this.chartOptions.xaxis.categories;

      for (let i = 0; i < data.length; i++) {
        const { day, month, year } = data[i];

        const monthName = this.getMonth(month);

        // Set series
        this.series[1].data.push(data[i].value);
        valAnt += data[i].value;
        this.series[0].data.push(valAnt);

        // Set chart splines
        if (this.overviewZoom.includes('y')) {
          categories.push(monthName);
        } else if (
          this.overviewZoom.includes('m')
            || this.overviewZoom.includes('w')
        ) {
          // Add spline condition
          let addToSplines = true;

          // Formats
          let concatMonth = false;
          let concatYear = false;

          if (['m_24'].includes(this.overviewZoom)) {
            const firstDaysIndexes = [];

            // Get index of the first day of each month/year
            for (let i = 0; i < data.length; i++) {
              const current = data[i];
              const previous = data[i - 1];

              if (
                !previous ||
                current.year !== previous.year ||
                current.month !== previous.month
              ) {
                firstDaysIndexes.push(i);
              }
            }

            addToSplines = !!(firstDaysIndexes.includes(i));
            concatMonth = false;
            concatYear = true;
          }

          // Only include days 1, 7, 14, 21
          if (['m_12', 'm_6', 'm_3', 'm_2'].includes(this.overviewZoom)) {
            addToSplines = day === 1 || day === 7 || day === 14 || day === 21;
            concatYear = false;
            concatMonth = true;
          }

          // Concat first of month
          if (this.overviewZoom.includes('w') || this.overviewZoom === 'm') {
            if (i === 0 || day === 1) {
              concatYear = false;
              concatMonth = true;
            }
          }

          if (addToSplines) {
            if (concatYear) {
              // Add concatenated month and year
              categories.push(`${monthName}/${year}`);
            } else if (concatMonth) {
              // Add concatenated month and day
              categories.push(`${monthName}/${day}`);
            } else {
              // Add day
              categories.push(day);
            }
          } else {
            // Add empty string
            categories.push('');
          }
        }
      }
    },
    setChartColors() {
      this.chartOptions.colors.unshift(this.primaryColor);
      this.chartOptions.colors.unshift(this.secondaryColor);
    },
    setOverview() {
      this.totalVerified = this.overview.TotalIds;

      this.totalIncomes[0].money = this.overview.totalIds;
      this.totalIncomes[1].money = this.overview.totCpf.number;
      this.totalIncomes[2].money = this.overview.totEmail.number;
      this.totalIncomes[3].money = this.overview.totCel.number;
      this.totalIncomes[4].money = this.overview.totEndereco.number;
    },
    setSystemOptions() {
      this.systemOptions = this.overview.idsBySystem.map(({ text, id }) => {
        return { text, value: Number(id) };
      });
    },
  },
};
</script>

<style>
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-heading {
  width: 100%;
  height: 36px;
  border-radius: 0.25rem;
}

.skeleton-footer {
  width: 30%;
}
</style>
