<template>
  <div>
    <vs-row vs-justify="start">
      <vs-col
        vs-lg="12"
        vs-xs="12"
        vs-sm="12"
      >
        <vs-card>
          <FanProfiles
            :chart="chart"
            :overview="overview"
            @get-chart="getChart"
            @get-overview="getOverview"
          />
        </vs-card>
      </vs-col>
    </vs-row>

    <ProjectBox
      box-name="overview"
      :use-loading="false"
    />
  </div>
</template>

<script>
/* Components */
import FanProfiles from '../../dashboard-components/box-fanbase/FanProfiles';
import ProjectBox from './ProjectBox.vue';

/* Services */
import System from '@/services/system';

const systemService = new System();

export default {
  name: 'ProjectGeral',
  components: {
    FanProfiles,
    ProjectBox,
  },
  data: () => ({
    chart: [],
    overview: {},
  }),
  computed: {
    /* Store */
    ownerId() {
      return this.$store.state.ownerID;
    },
  },
  created() {
    this.getChart({ ownerId: this.ownerId, options: { zoom: 'y' } });
    this.getOverview();
  },
  methods: {
    getChart(payload) {
      this.$store.dispatch('set_isLoadingActive', true);

      systemService.getOverviewChart(payload)
        .then((resp) => (this.chart = resp))
        .catch((err) => (alert(err)))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getOverview() {
      systemService.getDashOverview(this.ownerId)
        .then((resp) => (this.overview = resp))
        .catch((err) => alert(err));
    },
  },
};
</script>
