<template>
  <vs-row class="mt-4 justify-content-around">
    <vs-col
      v-for="(item, index) in totalincomes"
      :key="index"
      :class="[ item.gridlg, item.gridsm ]"
    >
      <div class="d-flex align-items-center">
        <div class="mr-2">
          <span
            :class="[ item.iconcolor ]"
            style="font-size: 2.5rem"
          >
            <i :class="[ item.icon ]" />
          </span>
        </div>

        <div>
          <span :class="[ item.textcolor ]">
            {{ item.text }}
          </span>

          <h3
            v-if="item.money | formatLocale"
            class="font-medium mb-0"
          >
            {{ item.money | formatLocale }}
          </h3>
          <div
            v-else
            class="skeleton skeleton-text"
          />
        </div>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
export default {
  name: 'IDProfileList',
  props: {
    totalincomes: {
      type: Array,
      required: true,
    },
  },
  created() {
    console.log('Total Incomes: ', this.totalincomes);
  },
};
</script>

<style>
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 20px;
  border-radius: 0.25rem;
}
</style>
